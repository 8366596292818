import { Route, Routes } from 'react-router-dom';
import './App.css';

import AuthProvider from './components/authprovider.component';
import PrivateRoute from './components/privateroute.component';
import BoardADC from './pages/boardadc.page';
import BoardCompras from './pages/boardcompras.page';
import BoardGerencia from './pages/boardgerencia.page';
import BoardRRHH from './pages/boardrrhh.page';
import Login from './pages/login.page';
import Profile from './pages/profile.page';
import ReporteBUK from './pages/reportebuk.page';
import ReporteCompras from './pages/reportecompras.page';
import ReporteReclutamiento from './pages/reportereclutamiento.page';
import ReporteRRHH from './pages/reporterrhh.page';


function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path='/profile' element={<Profile />} />
          <Route path='/compras' element={<BoardCompras />} />
          <Route path='/rrhh' element={<BoardRRHH />} />
          <Route path='/gerencia' element={<BoardGerencia />} />
          <Route path='/lideres' element={<BoardADC />} />
          <Route path="/reportecompras/:reporte_id/:section" element={<ReporteCompras />} />
          <Route path="/reporterrhh/:reporte_id/:section" element={<ReporteRRHH />} />
          <Route path="/reportereclu/:reporte_id/:section" element={<ReporteReclutamiento />} />
          <Route path="/reportebuk/:reporte_id/:section" element={<ReporteBUK />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
